<template>
	<template-base class="containerCard">
		<div class="d-flex">
			<h1 class="align-self-center">Identificadores de Localização</h1>
			<div class="spacer"></div>
		</div>
		<form @submit.prevent.stop="">
			<div class="container-pesquisa mb-1">
				<div class="input-group">
					<div class="input-group-prepend">
						<vue-multi-select
							class="multi-100"
							v-model="areaSelecionada"
							@input="checarArea"
							historyButton
							:options="{ multi: false, labelName: 'nome' }"
							:selectOptions="areasOpcoes"
							:btnLabel="() => areaSelecionada[0] ? areaSelecionada[0].nome : 'Selecione'"
						/>
					</div>
					<input
						type="search"
						class="form-control"
						aria-label="Filtrar por texto"
						v-model="query"
						placeholder="Digite para filtrar maquinas"
						data-cy="Pesquisa"
					/>
					<div class="input-group-append">
						<button
							class="btn btn-primary px-4"
							type="submit"
							@click="pesquisar"
							data-cy="Pesquisar">
							<SearchIcon/>
						</button>
					</div>
				</div>
			</div>
		</form>
		<div class="mt-2">
			<Tree
				v-if="possuiPermissao('GER_U_IDENTIFICADORES_MAQUINAS')"
				controls
				:loading="loading"
				:items="items"
				@cancelar-novo="cancelarNovo"
				@apagar="apagar"
				@adicionado-novo="adicionadoNovo"
				@edicao-grupo="mostrarEdicao"
			 />
		</div>
		<div>
			<b-modal ref="modalEdicao" hide-footer hide-header>
				<div class="d-block mb-2">
					<label>Nome: {{grupoInput}}</label>
				</div>
				<label>Identificador de Localização</label>
					<b-form-input
						type="text"
						v-model="identificadorLoc"
					>
					</b-form-input>
				<b-button class="mt-3 float-left" variant="secondary" @click="fecharModal">Fechar</b-button>
				<b-button
					class="mt-3 ml-1 float-right"
					variant="success"
					@click="salvarEdicao"
					>Salvar
				</b-button>
			</b-modal>
		</div>
	</template-base>
</template>

<script>
	import TemplateBase from "@/templates/Base";
	import Tree from "@/components/TreeGrupo";

	import VueMultiSelect from "vue-multi-select";
	import "vue-multi-select/dist/lib/vue-multi-select.css";

	import { MaquinasService } from "../../services/maquinas";
	import { AreasService } from "../../services/areas";
	import { possuiPermissao } from "../../helpers/permissions";

	export default {
		components: {
			TemplateBase,
			Tree,
			VueMultiSelect
		},

		data () {
			return {
				items: [],
				query: "",

				grupos: [],
				areas: [],
				maquinas: [],

				loading: false,
				criandoItem: false,

				mapa: false,

				areasService: new AreasService(),
				maquinasService: new MaquinasService(),

				grupoSelecionado: [],
				grupoOpcoes: [],
				grupoInput: "",
				grupoItem: null,

				areaSelecionada: [],
				areasOpcoes: [],

				maquinaSelecionada: [],
				maquinasOpcoes: [],

				identificadorLoc: "",

				possuiPermissao
			};
		},

		computed: {
			inputValidacao () {
				return this.grupoInput.length > 0;
			}
		},

		async mounted () {
			this.maquinas = (await this.maquinasService.listMachines()) || [];
			this.areas = (await this.areasService.listAreas()) || [];
			this.areasOpcoes = this.areas;
			this.treeGrupos("");
		},

		methods: {
			mostrarEdicao (item) {
				this.grupoInput = item.nome;
				this.grupoItem = item;
				this.identificadorLoc = item.identificador_localizacao || "";
				this.$refs.modalEdicao.show();
			},

			checarArea () {
				if (this.areaSelecionada[0]) {
					this.areas = this.areaSelecionada;
					this.treeGrupos("");
				}
			},

			salvarEdicao () {
				const config = {
					timeout: 2000,
					showProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true
				};

				this.$snotify.async("Aguarde...", "Salvando", async () => {
					try {
						const maquina = this.grupoItem;
						const payload = {
							maquina_id: maquina.id_maquina,
							identificadorLoc: this.identificadorLoc
						};

						await this.maquinasService.setIdentificador(payload);
						this.grupoItem.identificador_localizacao = this.identificador_localizacao;
						maquina.identificador_localizacao = this.identificadorLoc;

						this.grupoItem = null;
						this.treeGrupos();
						this.fecharModal();

						return {
							title: "Sucesso!",
							body: "Identificador de localização atualizado.",
							config
						};
					} catch (error) {
						throw {
							title: "Falha!",
							body: "Erro ao salvar identificador de localização.",
							config
						};
					}
				});

			},

			fecharModal () {
				this.$refs.modalEdicao.hide();
			},

			async treeGrupos (query) {
				this.grupos = [];
				this.loading = true;
				const grupos = this.grupos;
				const areas = this.areas;
				const maquinas = await this.maquinasService.listMachines(query);
				let contadorID = 1;

				areas.forEach(a => {
					if (grupos) {
						const jaExistente = grupos.find(g => g.id_area == a.id && g.tipo == a.tipo);
						if (!jaExistente) {
							const area = {
								id: contadorID++,
								nome: a.nome,
								id_area: a.id,
								id_grupo_pai: a.id_area_pai || null,
								tipo: "area"
							};
							this.grupos.push(area);
						}
					}
				});

				maquinas.forEach(m => {
					const areaPertencente = this.grupos.find(g => g.tipo == "area" && g.id_area == m.id_area);
					const jaCadastrada = grupos.indexOf(m);
					if (jaCadastrada < 0 && areaPertencente) {
						const maquina = {
							id: contadorID++,
							nome: m.nome,
							id_maquina: m.id,
							id_grupo_pai: areaPertencente.id || null,
							tipo: "maquina",
							identificador_localizacao: m.identificador_localizacao || ""
						};
						this.grupos.push(maquina);
					}
				});

				const treeNodes = {};
				const forest = [];

				for (const grupo of grupos) {
					if (grupo.id_grupo_pai == null || !grupos.find(g => g.id === grupo.id_grupo_pai))
						forest.push(grupo);
					else if (treeNodes[grupo.id_grupo_pai])
						treeNodes[grupo.id_grupo_pai].push(grupo);
					else
						treeNodes[grupo.id_grupo_pai] = [grupo];
				}

				for (const grupo of grupos) {
					delete grupo.createdAt;
					delete grupo.updatedAt;

					grupo.opened = false;
					grupo.checked = false;
					grupo.editing = false;
					grupo.children = treeNodes[grupo.id] || [];
				}

				for (const grupo of grupos) {
					grupo.children.sort((a, b) => {
						const folder = (b.children.length > 0) - (a.children.length > 0);
						return folder ? folder : a.nome.localeCompare(b.nome);
					});
				}

				this.query = query;
				this.grupos = grupos;
				this.items = forest;
				this.loading = false;
			},

			async pesquisar () {
				this.treeGrupos(this.query);
			},

			async apagar (id) {
				const idx = this.items.findIndex((c) => c.id === id);
				if (idx >= 0) this.items.splice(idx, 1);

				const idx2 = this.grupos.findIndex((c) => c.id === id);
				if (idx2 >= 0) this.grupos.splice(idx2, 1);
			},

			cancelarNovo () {
				this.criandoItem = false;
				this.items.pop();
				this.grupos.pop();
			},

			adicionadoNovo () {
				this.criandoItem = false;
			}
		}
	};
</script>

<style scoped>
	.containerCard {
		max-width: 90.5%;
	}
</style>

<style>
	.invalid-select {
		width: 100%;
		margin-top: 0.25rem;
		font-size: 80%;
		color: #dc3545;
		display: block;
	}

	.form-control {
		height: auto !important;
	}

	.btn-is-invalid > button {
		border-color: #dc3545 !important;
	}

	.btn-is-invalid > button:focus {
		border-color: #d1c4c5 !important;
		-webkit-box-shadow: 0 0 0 0.2rem rgb(220 53 69 / 25%);
		box-shadow: 0 0 0 0.2rem rgb(220 53 69 / 25%) !important;
	}
</style>
